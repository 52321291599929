import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

import { withFirebase } from '../Firebase'
import StepProgress from '../StepProgress'

const Tenant = ({ authUser, domRef, firebase, step, steps, toNext }) => {
  const [formData, setFormData] = useState({
    applicantType: 'Natural Person',
    applicationType: 'Single Application',
    companyName: '',
    companyNumber: '',
  })
  const [applicantNumber, setApplicantNumber] = useState(2)
  const [applicants, setApplicants] = useState([
    {
      heading: 'Primary Applicant',
      firstName: '',
      lastName: '',
      identityNumber: '',
      employmentType: '',
      grossIncome: '',
      netIncome: '',
      maritalStatus: '',
    },
  ])
  const [error, setError] = useState({ message: '' })

  const applicantTypes = ['Natural Person', 'Legal Entity']
  const applicationTypes = ['Single Application', 'Joint Application']
  const employmentTypes = ['Full Time', 'Part Time', 'Contract']
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widow', 'Widower']
  const formInvalid = false

  const onSubmit = () => {
    firebase
      .createProfile({
        tenant: formData,
        userId: authUser.uid,
      })
      .then(() => {
        resetForm()
        toNext()
      })
      .catch(error => {
        console.log(error)
        setError(error)
      })
  }

  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeApplicantType = event => {
    if (event.target.value === applicantTypes[1]) {
      setApplicants([
        {
          heading: 'Company Representative',
          firstName: '',
          lastName: '',
          identityNumber: '',
          employmentType: '',
          grossIncome: '',
          netIncome: '',
          maritalStatus: '',
        },
      ])
    } else {
      setApplicants([
        {
          heading: 'Primary Applicant',
          firstName: '',
          lastName: '',
          identityNumber: '',
          employmentType: '',
          grossIncome: '',
          netIncome: '',
          maritalStatus: '',
        },
      ])
    }
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeApplicationType = event => {
    if (event.target.value === applicationTypes[1]) {
      addApplicants(2)
    } else {
      addApplicants(1)
    }
    setFormData({ ...formData, [event.target.name]: event.target.value })
    setApplicantNumber(2)
  }

  const onChangeApplicantNumber = event => {
    const number = parseInt(event.target.value)
    addApplicants(number)
    setApplicantNumber(number)
  }

  const addApplicants = count => {
    const applicantsCopy = applicants.slice(0, 1)
    for (let i = 1; i < count; i++) {
      applicantsCopy.push({
        heading: `Co-applicant ${i}`,
        firstName: '',
        lastName: '',
        identityNumber: '',
        employmentType: '',
        grossIncome: '',
        netIncome: '',
        maritalStatus: '',
      })
    }
    setApplicants(applicantsCopy)
  }

  const onChangeMaritalStatus = (event, index) => {
    let applicantsCopy = [...applicants]

    const updatedField = event.target.name
    applicantsCopy[index][updatedField] = event.target.value

    if (applicantsCopy[index][updatedField] === maritalStatuses[1]) {
      applicantsCopy.push({
        heading: `Co-applicant ${index + 1} Spouse`,
        firstName: '',
        lastName: '',
        identityNumber: '',
        employmentType: '',
        grossIncome: '',
        netIncome: '',
        maritalStatus: '',
      })
    } else {
      applicantsCopy = applicants.slice(index, 1)
    }

    setApplicants(applicantsCopy)
  }

  const resetForm = () => {
    setFormData({
      applicantType: '',
      applicationType: '',
      companyName: '',
      companyNumber: '',
    })
  }

  return (
    <div className="profile-step" ref={domRef}>
      <h1 className="has-text-white">Complete your Registration</h1>
      <div className="box">
        <div className="section">
          <StepProgress step={step} steps={steps} />
        </div>
        <div className="section">
          <h4>Please complete registration for Tenant</h4>
          <form>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'applicantType'}>
                    Applicant Type
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        name="applicantType"
                        value={formData.applicantType}
                        onChange={event => onChangeApplicantType(event)}
                      >
                        {applicantTypes.map(type => {
                          return <option key={type}>{type}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'applicationType'}>
                    Application Type
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        name="applicationType"
                        value={formData.applicationType}
                        onChange={onChangeApplicationType}
                      >
                        {applicationTypes.map(type => {
                          return <option key={type}>{type}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {formData.applicantType === applicantTypes[1] && (
                  <div className="field">
                    <label className="label" htmlFor={'companyNumber'}>
                      Company Registration Number
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        name="companyNumber"
                        value={formData.companyNumber}
                        onChange={onChange}
                        type="text"
                        placeholder="Company Registration Number"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="column">
                {formData.applicantType === applicantTypes[1] && (
                  <div className="field">
                    <label className="label" htmlFor={'companyName'}>
                      Registered Company Name
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        name="companyName"
                        value={formData.companyName}
                        onChange={onChange}
                        type="text"
                        placeholder="Registered Company Name"
                      />
                    </div>
                  </div>
                )}
                {formData.applicationType === applicationTypes[1] && (
                  <div className="field">
                    <label className="label" htmlFor={'applicantNumber'}>
                      Number of Applicants
                    </label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          name="applicantNumber"
                          value={applicantNumber}
                          onChange={onChangeApplicantNumber}
                        >
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {applicants.map((field, index) => (
              <Fragment key={v4()}>
                <h4>{field.heading}</h4>
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor={'firstName'}>
                        First Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="firstName"
                          value={field.firstName}
                          onChange={onChange}
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'identityNumber'}>
                        ID / Passport Number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="identityNumber"
                          value={field.identityNumber}
                          onChange={onChange}
                          type="text"
                          placeholder="ID / Passport Number"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'grossIncome'}>
                        Gross Income
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="grossIncome"
                          value={field.grossIncome}
                          onChange={onChange}
                          type="text"
                          placeholder="Gross Income"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'maritalStatus'}>
                        Marital Status
                      </label>
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            name="maritalStatus"
                            value={field.maritalStatus}
                            onChange={event =>
                              onChangeMaritalStatus(event, index)
                            }
                          >
                            {maritalStatuses.map(status => {
                              return <option key={status}>{status}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor={'lastName'}>
                        Last Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="lastName"
                          value={field.lastName}
                          onChange={onChange}
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'employmentType'}>
                        Employment Type
                      </label>
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            name="employmentType"
                            value={field.employmentType}
                            onChange={onChange}
                          >
                            {employmentTypes.map(type => {
                              return <option key={type}>{type}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'netIncome'}>
                        Net Income
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="netIncome"
                          value={field.netIncome}
                          onChange={onChange}
                          type="text"
                          placeholder="Net Income"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
            <div className="field has-text-centered">
              <button
                className="button is-link is-rounded is-medium"
                type="button"
                onClick={onSubmit}
                disabled={formInvalid}
              >
                Next
              </button>
            </div>
            {error && <p>{error.message}</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

Tenant.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
  domRef: PropTypes.func,
  step: PropTypes.number,
  steps: PropTypes.array,
  toNext: PropTypes.func,
}

export default withFirebase(Tenant)
