import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import ProfileImage from '../ProfileImage'
import Modal from '../Modal'

import mainImage from '../../../static/img/professional-dark.svg'
import hoverImage from '../../../static/img/professional-light.svg'

const ProfessionalSelect = props => {
  const [showModal, setShowModal] = useState(false)
  const { selected, setSelected } = props

  const onSelect = () => {
    if (!selected) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    setSelected(true)
    setShowModal(false)
  }

  const onShow = () => {
    setShowModal(true)
  }

  return (
    <Fragment>
      <div className={selected ? `field profile-checked` : `field`}>
        <article className="media">
          <figure className="media-left">
            <p
              className={
                selected
                  ? 'image is-96x96 is-clickable profile-image-checked'
                  : 'image is-96x96 is-clickable profile-image'
              }
              onClick={onSelect}
              onKeyDown={onSelect}
              role="presentation"
            >
              <ProfileImage
                src={mainImage}
                hoverSrc={hoverImage}
                selected={selected}
              />
            </p>
          </figure>
          <div className="media-content">
            <div className="content">
              <h4 className="is-uppercase has-text-weight-bold has-text-primary-dark">
                Property Industry Professionals
              </h4>
              <p>
                Own your deal from beginning to end. It's not how you start…
                it's how you finish! Click here if you are a bank, agent or bond
                originator.
              </p>
              <small>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="has-text-weight-bold has-text-primary-dark is-underlined"
                  onClick={onShow}
                  onKeyDown={onShow}
                  role="presentation"
                >
                  Why should I disclose my information?
                </a>
              </small>
            </div>
          </div>
        </article>
      </div>
      <Modal
        title="Why should I disclose my information?"
        showModal={showModal}
        setShowModal={setShowModal}
        formInvalid={false}
        action="I'm sold, let's go for it!"
        submitHandler={onSubmit}
      >
        <p className="has-text-primary-dark is-size-7">
          The 99Sense economic ecosystem is based on a trusted platform. 99Sense
          is obligated to verify the profiles of our property sellers to ensure
          the safety of all participants of the industry including the Buyer,
          Seller and Estate Agents. This high standard standard and integrity
          offered by the 99Sense economic ecosystem maintains its credibility in
          the industry.
        </p>
      </Modal>
    </Fragment>
  )
}

ProfessionalSelect.propTypes = {
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
}

export default ProfessionalSelect
