import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import ProfileImage from '../ProfileImage'
import Modal from '../Modal'

import mainImage from '../../../static/img/buyer-dark.svg'
import hoverImage from '../../../static/img/buyer-light.svg'

const BuyerSelect = props => {
  const [showModal, setShowModal] = useState(false)
  const { selected, setSelected } = props

  const onSelect = () => {
    if (!selected) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    setSelected(true)
    setShowModal(false)
  }

  const onShow = () => {
    setShowModal(true)
  }

  return (
    <Fragment>
      <div className={selected ? `field profile-checked` : `field`}>
        <article className="media">
          <figure className="media-left">
            <p
              className={
                selected
                  ? 'image is-96x96 is-clickable profile-image-checked'
                  : 'image is-96x96 is-clickable profile-image'
              }
              onClick={onSelect}
              onKeyDown={onSelect}
              role="presentation"
            >
              <ProfileImage
                src={mainImage}
                hoverSrc={hoverImage}
                selected={selected}
              />
            </p>
          </figure>
          <div className="media-content">
            <div className="content">
              <h4 className="has-text-weight-bold has-text-primary-dark is-uppercase">
                Buyers
              </h4>
              <p>
                Unleash the buying power that's now in your hands and reap the
                benefits that extend far beyond the sale.
              </p>
              <small>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="has-text-weight-bold has-text-primary-dark is-underlined"
                  onClick={onShow}
                  onKeyDown={onShow}
                  role="presentation"
                >
                  Why should I disclose my information?
                </a>
              </small>
            </div>
          </div>
        </article>
      </div>
      <Modal
        title="Why should I disclose my information?"
        showModal={showModal}
        setShowModal={setShowModal}
        formInvalid={false}
        action="I'm sold, let's go for it!"
        submitHandler={onSubmit}
      >
        <p className="has-text-primary-dark is-size-7">
          To reap the benefits of the 99sense platform, the property buyer needs
          to be prequalified in order to establish affordability.
        </p>
        <strong className="has-text-primary-dark is-size-7">
          Benefits of being a pre-qualified home purchaser:
        </strong>
        <ul className="brand-list has-text-primary-dark is-size-7">
          <li>It sets a clear budget</li>
          <li>It shows sellers you're serious</li>
          <li>It saves time</li>
        </ul>
        <strong className="has-text-primary-dark is-size-7">
          What Do We Look For During Pre-qualification?
        </strong>
        <br />
        <br />
        <p className="has-text-primary-dark is-size-7">
          Prequalification takes into account several factors, ranging from
          credit profile to gross income.
        </p>
        <strong className="has-text-primary-dark is-size-7">
          Here's how 99Sense unleashes the power of a pre-qualified buyer.
        </strong>
        <br />
        <br />
        <p className="has-text-primary-dark is-size-7">
          Gone are the days of jumping in your car and hopping from show house
          to show house.
        </p>
        <p className="has-text-primary-dark is-size-7">
          Pre-qualified sellers and estate agents in your preferred area of
          interest invite you to personally view their property via the 99Sense
          platform. The 99Sense platform gives you, the pre-qualified buyer, the
          power to either decline an offer to view or accept the offer, thus
          saving you time and money.
        </p>
        <strong className="has-text-primary-dark is-size-7">
          Here's what makes the 99Sense ecosystem unique
        </strong>
        <br />
        <br />
        <p className="has-text-primary-dark is-size-7">
          Each time an 'Offer to Purchase' is signed, this triggers a series of
          additional benefits, which includes, but not limited to, discounted:
        </p>
        <ul className="brand-list has-text-primary-dark is-size-7">
          <li>Bond and transfer costs</li>
          <li>
            Compliance certificates: electrical, entomologist, gas, plumbing and
            electrical fence
          </li>
          <li>Furniture removal services</li>
          <li>Home security services</li>
          <li>Local and national hardware suppliers</li>
          <li>Home appliance suppliers</li>
          <li>And what's a home without a new car?</li>
          <li>
            Preferential insurance quotation for home, contents &amp; vehicles
          </li>
        </ul>
        <strong className="has-text-primary-dark is-size-7">
          All these service providers will have an option to quote via the
          99Sense system so that you can secure the best deal for the rest of
          your life!
        </strong>
      </Modal>
    </Fragment>
  )
}

BuyerSelect.propTypes = {
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
}

export default BuyerSelect
