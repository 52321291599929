import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import ProfileImage from '../ProfileImage'
import Modal from '../Modal'

import mainImage from '../../../static/img/seller-dark.svg'
import hoverImage from '../../../static/img/seller-light.svg'

const SellerSelect = props => {
  const [showModal, setShowModal] = useState(false)
  const { selected, setSelected } = props

  const onSelect = () => {
    if (!selected) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    setSelected(true)
    setShowModal(false)
  }

  const onShow = () => {
    setShowModal(true)
  }

  return (
    <Fragment>
      <div className={selected ? `field profile-checked` : `field`}>
        <article className="media">
          <figure className="media-left">
            <p
              className={
                selected
                  ? 'image is-96x96 is-clickable profile-image-checked'
                  : 'image is-96x96 is-clickable profile-image'
              }
              onClick={onSelect}
              onKeyDown={onSelect}
              role="presentation"
            >
              <ProfileImage
                src={mainImage}
                hoverSrc={hoverImage}
                selected={selected}
              />
            </p>
          </figure>
          <div className="media-content">
            <div className="content">
              <h4 className="has-text-weight-bold has-text-primary-dark is-uppercase">
                Sellers
              </h4>
              <p>Select prequalified buyers just a click away.</p>
              <small>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="has-text-weight-bold has-text-primary-dark is-underlined"
                  onClick={onShow}
                  onKeyDown={onShow}
                  role="presentation"
                >
                  Why should I disclose my information?
                </a>
              </small>
            </div>
          </div>
        </article>
      </div>
      <Modal
        title="Why should I disclose my information?"
        showModal={showModal}
        setShowModal={setShowModal}
        formInvalid={false}
        action="I'm sold, let's go for it!"
        submitHandler={onSubmit}
      >
        <p className="has-text-primary-dark is-size-7">
          The 99Sense economic ecosystem is based on a trusted platform. 99Sense
          is obligated to verify the profiles of our property sellers to ensure
          the safety of all participants of the industry including the Buyer,
          Seller and Estate Agents. This high standard standard and integrity
          offered by the 99Sense economic ecosystem maintains its credibility in
          the industry.
        </p>
        <p className="has-text-primary-dark is-size-7">
          Bottom line - our clients safety comes first!
        </p>
        <strong className="has-text-primary-dark is-size-7">
          Benefits of being a verified home seller:
        </strong>
        <ul className="brand-list has-text-primary-dark is-size-7">
          <li>
            Shows you're serious about selling your property at the highest
            price in the shortest period of time to a pre-qualified buyer.
            Saving you time and money!
          </li>
          <li>
            Access to verified Service providers during the property sale
            process.
          </li>
          <li>
            One of the major benefits is that you would have access to
            reputable, vetted Transferring Attorneys and Estate Agents at
            competitive rates.
          </li>
          <li>
            Value added services that benefit the buyers financially making your
            property more marketable than the traditional way of selling
            property.
          </li>
        </ul>
        <strong className="has-text-primary-dark is-size-7">
          Here's what makes the 99Sense ecosystem unique
        </strong>
        <br />
        <br />
        <p className="has-text-primary-dark is-size-7">
          Each time an 'Offer to Purchase' is signed, this triggers a series of
          additional benefits, which includes, but not limited to, discounted:
        </p>
        <ul className="brand-list has-text-primary-dark is-size-7">
          <li>Bond and transfer costs</li>
          <li>
            Compliance certificates: electrical, entomologist, gas, plumbing and
            electrical fence
          </li>
          <li>Furniture removal services</li>
          <li>Home security services</li>
          <li>Local and national hardware suppliers</li>
          <li>Home appliance suppliers</li>
          <li>And what's a home without a new car?</li>
          <li>
            Preferential insurance quotation for home, contents &amp; vehicles
          </li>
        </ul>
        <strong className="has-text-primary-dark is-size-7">
          All these service providers will have an option to quote via the
          99Sense system so that you can secure the best deal for the rest of
          your life!
        </strong>
      </Modal>
    </Fragment>
  )
}

SellerSelect.propTypes = {
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
}

export default SellerSelect
