import React, { useEffect, useRef, useState } from 'react'
import { compose } from 'recompose'

import Layout from '../../components/Layout'
import ProfileSelector from '../../components/ProfileSelector'

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from '../../components/Session'

const JoinNetworkPageBase = () => {
  const [step, setStep] = useState(0)
  const [steps, setSteps] = useState([ProfileSelector])
  const [labels, setLabels] = useState([])
  const refs = useRef([])

  useEffect(() => {
    refs.current[step]?.scrollIntoView({ behavior: 'smooth' })
  }, [step])

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              {steps
                .filter((_, index) => index <= step)
                .map((Step, index) => (
                  <Step
                    key={index}
                    authUser={authUser}
                    domRef={ref => (refs.current[index] = ref)}
                    toPrev={() => {
                      refs.current[index - 1]?.scrollIntoView({
                        behavior: 'smooth',
                      })
                    }}
                    toNext={() => {
                      if (step === index + 1) {
                        refs.current[index + 1]?.scrollIntoView({
                          behavior: 'smooth',
                        })
                      }
                      setStep(index + 1)
                    }}
                    setStep={setStep}
                    step={index}
                    setSteps={setSteps}
                    steps={steps}
                    setLabels={setLabels}
                    labels={labels}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  )
}

const condition = authUser => !!authUser

const JoinNetworkPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(JoinNetworkPageBase)

const JoinNetwork = () => {
  return (
    <Layout>
      <section className="section section-gradient">
        <div className="container">
          <div className="content">
            <JoinNetworkPage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default JoinNetwork
