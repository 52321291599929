import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

const StepProgress = props => {
  const { step, steps } = props
  const total = Object.entries(steps).length - 1
  const percent = (100 * step) / total

  return (
    <div id="progress">
      <div id="progress-bar" style={{ width: `${percent}%` }}></div>
      <ul id="progress-num">
        {Object.entries(steps)
          .filter(([_, value]) => value)
          .map((_, index) => (
            <li key={v4()} className={index === step ? `step active` : `step`}>
              {index + 1}
            </li>
          ))}
      </ul>
    </div>
  )
}

StepProgress.propTypes = {
  step: PropTypes.number,
  steps: PropTypes.array,
}

export default StepProgress
