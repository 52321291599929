import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { withFirebase } from '../Firebase'
import StepProgress from '../StepProgress'

const Provider = props => {
  const [formData, setFormData] = useState({
    serviceProviderType: '',
    companyName: '',
    companyNumber: '',
    firstName: '',
    lastName: '',
    identityNumber: '',
    mobileNumber: '',
    workNumber: '',
  })
  const [error, setError] = useState({ message: '' })
  const { authUser, domRef, step, steps, toNext } = props
  const serviceProviderTypes = ['Natural Person', 'Legal Entity']
  const formInvalid =
    formData.serviceProviderType === '' ||
    formData.companyName === '' ||
    formData.companyNumber === '' ||
    formData.firstName === '' ||
    formData.lastName === '' ||
    formData.identityNumber === '' ||
    formData.mobileNumber === '' ||
    formData.workNumber === ''

  const onSubmit = () => {
    props.firebase
      .createProfile({
        provider: formData,
        userId: authUser.uid,
      })
      .then(() => {
        resetForm()
        toNext()
      })
      .catch(error => {
        console.log(error)
        setError(error)
      })
  }

  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const resetForm = () => {
    setFormData({
      serviceProviderType: '',
      companyName: '',
      companyNumber: '',
      firstName: '',
      lastName: '',
      identityNumber: '',
      mobileNumber: '',
      workNumber: '',
    })
  }

  return (
    <div className="profile-step" ref={domRef}>
      <h1 className="has-text-white">Complete your Registration</h1>
      <div className="box">
        <div className="section">
          <StepProgress step={step} steps={steps} />
        </div>
        <div className="section">
          <h4>Please complete registration for Service Provider</h4>
          <form>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'serviceProviderType'}>
                    Applicant Type
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        name="serviceProviderType"
                        value={formData.serviceProviderType}
                        onChange={onChange}
                      >
                        <option>Select type</option>
                        {serviceProviderTypes.map(type => {
                          return <option key={type}>{type}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'companyNumber'}>
                    Company Registration Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="companyNumber"
                      value={formData.companyNumber}
                      onChange={onChange}
                      type="text"
                      placeholder="Company Registration Number"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'companyName'}>
                    Registered Company Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="companyName"
                      value={formData.companyName}
                      onChange={onChange}
                      type="text"
                      placeholder="Registered Company Name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <h4>Company Representative</h4>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'firstName'}>
                    First Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="firstName"
                      value={formData.firstName}
                      onChange={onChange}
                      type="text"
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'identityNumber'}>
                    ID / Passport Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="identityNumber"
                      value={formData.identityNumber}
                      onChange={onChange}
                      type="text"
                      placeholder="ID / Passport Number"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'workNumber'}>
                    Work Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="workNumber"
                      value={formData.workNumber}
                      onChange={onChange}
                      type="text"
                      placeholder="Work Number"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'lastName'}>
                    Last Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="lastName"
                      value={formData.lastName}
                      onChange={onChange}
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'mobileNumber'}>
                    Mobile Number
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      name="mobileNumber"
                      value={formData.mobileNumber}
                      onChange={onChange}
                      type="text"
                      placeholder="Mobile Number"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field has-text-centered">
              <button
                className="button is-link is-rounded is-medium"
                type="button"
                onClick={onSubmit}
                disabled={formInvalid}
              >
                Next
              </button>
            </div>
            {error && <p>{error.message}</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

Provider.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
  domRef: PropTypes.func,
  step: PropTypes.number,
  steps: PropTypes.array,
  toNext: PropTypes.func,
}

export default withFirebase(Provider)
