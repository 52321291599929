import React, { useState } from 'react'
import PropTypes from 'prop-types'

import BuyerSelect from './BuyerSelect'
import SellerSelect from './SellerSelect'
import TenantSelect from './TenantSelect'
import LandlordSelect from './LandlordSelect'
import ProfessionalSelect from './ProfessionalSelect'
import ProviderSelect from './ProviderSelect'

import Buyer from '../../components/Buyer'
import Landlord from '../../components/Landlord'
import Professional from '../../components/Professional'
import Provider from '../../components/Provider'
import Seller from '../../components/Seller'
import Tenant from '../../components/Tenant'
import ThankYou from '../../components/ThankYou'

const ProfileSelector = props => {
  const [buyerSelected, setBuyerSelected] = useState(false)
  const [sellerSelected, setSellerSelected] = useState(false)
  const [tenantSelected, setTenantSelected] = useState(false)
  const [landlordSelected, setLandlordSelected] = useState(false)
  const [professionalSelected, setProfessionalSelected] = useState(false)
  const [providerSelected, setProviderSelected] = useState(false)
  const { domRef, setLabels, steps, setSteps, toNext } = props

  const formInvalid =
    !buyerSelected &&
    !sellerSelected &&
    !tenantSelected &&
    !landlordSelected &&
    !professionalSelected &&
    !providerSelected

  const onSubmit = () => {
    const stepsCopy = steps.slice(0, 1)
    const selectedLabels = []

    if (buyerSelected) {
      selectedLabels.push('Buyer')
      stepsCopy.push(Buyer)
    }

    if (sellerSelected) {
      selectedLabels.push('Seller')
      stepsCopy.push(Seller)
    }

    if (tenantSelected) {
      selectedLabels.push('Tenant')
      stepsCopy.push(Tenant)
    }

    if (landlordSelected) {
      selectedLabels.push('Landlord')
      stepsCopy.push(Landlord)
    }

    if (professionalSelected) {
      selectedLabels.push('Industry Professional')
      stepsCopy.push(Professional)
    }

    if (providerSelected) {
      selectedLabels.push('Service Provider')
      stepsCopy.push(Provider)
    }

    stepsCopy.push(ThankYou)

    setLabels(selectedLabels)
    setSteps(stepsCopy)

    toNext()
  }

  return (
    <div className="profile-step" ref={domRef}>
      <h1 className="has-text-white">Select your Profile/s</h1>
      <div className="box">
        <div className="section">
          <form>
            <BuyerSelect
              selected={buyerSelected}
              setSelected={setBuyerSelected}
            />
            <SellerSelect
              selected={sellerSelected}
              setSelected={setSellerSelected}
            />
            <TenantSelect
              selected={tenantSelected}
              setSelected={setTenantSelected}
            />
            <LandlordSelect
              selected={landlordSelected}
              setSelected={setLandlordSelected}
            />
            <ProfessionalSelect
              selected={professionalSelected}
              setSelected={setProfessionalSelected}
            />
            <ProviderSelect
              selected={providerSelected}
              setSelected={setProviderSelected}
            />
            <div className="field has-text-centered">
              <button
                className="button is-link is-rounded is-medium"
                type="button"
                onClick={onSubmit}
                disabled={formInvalid}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

ProfileSelector.propTypes = {
  domRef: PropTypes.func,
  labels: PropTypes.array,
  setLabels: PropTypes.func,
  steps: PropTypes.array,
  setSteps: PropTypes.func,
  toNext: PropTypes.func,
}

export default ProfileSelector
