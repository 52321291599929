import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ProfileImage = ({ src, hoverSrc, style, selected, className }) => {
  const [imageSrc, setImageSrc] = useState(src)

  useEffect(() => {
    if (selected) {
      setImageSrc(hoverSrc)
    } else {
      setImageSrc(src)
    }
  }, [src, hoverSrc, selected])

  return (
    // eslint-disable-next-line
    <img src={imageSrc} style={style} className={className} />
  )
}

ProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  hoverSrc: PropTypes.string.isRequired,
  style: PropTypes.string,
  selected: PropTypes.bool,
  className: PropTypes.string,
}

export default ProfileImage
