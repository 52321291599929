import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'

import { withFirebase } from '../Firebase'
import StepProgress from '../StepProgress'

const Landlord = ({ authUser, domRef, firebase, step, steps, toNext }) => {
  const [formData, setFormData] = useState({
    ownerType: 'Natural Person',
    ownershipType: 'Single Ownership',
  })
  const [ownershipNumber, setOwnershipNumber] = useState(2)
  const [owners, setOwners] = useState([
    {
      heading: 'Primary Owner',
      firstName: '',
      lastName: '',
      identityNumber: '',
      maritalStatus: '',
    },
  ])
  const [error, setError] = useState({ message: '' })

  const ownerTypes = ['Natural Person', 'Legal Entity']
  const ownershipTypes = ['Single Ownership', 'Joint Ownership']
  const maritalStatuses = ['Single', 'Married', 'Divorced', 'Widow', 'Widower']
  const formInvalid = false

  const onSubmit = () => {
    firebase
      .createProfile({
        landlord: formData,
        userId: authUser.uid,
      })
      .then(() => {
        resetForm()
        toNext()
      })
      .catch(error => {
        setError(error)
      })
  }

  const onChange = (index, event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeOwnershipType = event => {
    if (event.target.value === ownershipTypes[1]) {
      syncOwnerFields(2)
    } else {
      syncOwnerFields(1)
    }
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeOwnershipNumber = event => {
    const number = parseInt(event.target.value)
    syncOwnerFields(number)
    setOwnershipNumber(number)
  }

  const syncOwnerFields = count => {
    const ownersCopy = owners.slice(0, 1)
    for (let i = 1; i < count; i++) {
      ownersCopy.push({
        heading: `Co-owner ${i}`,
        firstName: '',
        lastName: '',
        identityNumber: '',
        maritalStatus: '',
      })
    }
    setOwners(ownersCopy)
  }

  const resetForm = () => {
    setFormData({
      ownerType: '',
      ownershipType: '',
    })
  }

  return (
    <div className="profile-step" ref={domRef}>
      <h1 className="has-text-white">Complete your Registration</h1>
      <div className="box">
        <div className="section">
          <StepProgress step={step} steps={steps} />
        </div>
        <div className="section">
          <h4>Please complete registration for Landlord</h4>
          <form>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label" htmlFor={'ownerType'}>
                    Owner Type
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        name="ownerType"
                        value={formData.ownerType}
                        onChange={onChange}
                      >
                        {ownerTypes.map(type => {
                          return <option key={type}>{type}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'ownershipType'}>
                    Number of property owners
                  </label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        name="ownershipType"
                        value={formData.ownershipType}
                        onChange={onChangeOwnershipType}
                      >
                        {ownershipTypes.map(type => {
                          return <option key={type}>{type}</option>
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                {formData.ownershipType === ownershipTypes[1] && (
                  <div className="field">
                    <label className="label" htmlFor={'ownershipNumber'}>
                      Number of Applicants
                    </label>
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          name="ownershipNumber"
                          value={ownershipNumber}
                          onChange={onChangeOwnershipNumber}
                        >
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {owners.map((field, index) => (
              <div key={v4()}>
                {formData.ownershipType === ownershipTypes[1] && (
                  <h4>{field.heading}</h4>
                )}
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor={'firstName'}>
                        First Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="firstName"
                          value={field.firstName}
                          onChange={onChange}
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'identityNumber'}>
                        ID / Passport Number
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="identityNumber"
                          value={field.identityNumber}
                          onChange={onChange}
                          type="text"
                          placeholder="ID / Passport Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor={'lastName'}>
                        Last Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          name="lastName"
                          value={field.lastName}
                          onChange={onChange}
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'maritalStatus'}>
                        Marital Status
                      </label>
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            name="maritalStatus"
                            value={field.maritalStatus}
                            onChange={onChange}
                          >
                            {maritalStatuses.map(status => {
                              return <option key={status}>{status}</option>
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="field has-text-centered">
              <button
                className="button is-link is-rounded is-medium"
                type="button"
                onClick={onSubmit}
                disabled={formInvalid}
              >
                Next
              </button>
            </div>
            {error && <p>{error.message}</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

Landlord.propTypes = {
  authUser: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
  }),
  domRef: PropTypes.func,
  step: PropTypes.number,
  steps: PropTypes.array,
  toNext: PropTypes.func,
}

export default withFirebase(Landlord)
