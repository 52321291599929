import React from 'react'

const Modal = props => {
  const active = props.showModal ? 'is-active' : ''

  const onShow = () => {
    props.setShowModal(!props.showModal)
  }

  return (
    <div className={`modal ${active}`}>
      <div
        className="modal-background"
        onClick={onShow}
        onKeyDown={onShow}
        role="button"
        aria-label="Close"
        tabIndex={0}
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <span className="modal-card-title is-size-6">{props.title}</span>
          <button
            type="button"
            onClick={onShow}
            className="delete"
            aria-label="close"
          />
        </header>
        <section className="modal-card-body">{props.children}</section>
        <footer className="modal-card-foot">
          <button
            className="button is-info"
            disabled={props.formInvalid}
            onClick={props.submitHandler}
          >
            {props.action}
          </button>
          <button onClick={onShow} className="button">
            Cancel
          </button>
        </footer>
      </div>
    </div>
  )
}

export default Modal
