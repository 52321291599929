import React from 'react'
import PropTypes from 'prop-types'

import StepProgress from '../StepProgress'

const ThankYou = props => {
  const { domRef, labels, step, steps, toNext } = props

  console.log(steps)

  return (
    <div className="profile-step" ref={domRef}>
      <h1 className="has-text-white">Thank you for Registering!</h1>
      <div className="box">
        <div className="section">
          <StepProgress step={step} steps={steps} />
        </div>
        <div className="section is-medium has-text-centered">
          <div className="notification is-success is-light">
            <div className="section is-medium">
              <h1 className="title">Success!</h1>
              <h4 className="subtitle">
                Congratulations, you have completed your registration as a{' '}
                <strong>{labels.join(', ')}</strong>.
              </h4>
            </div>
          </div>
          <button
            className="button is-link is-rounded is-medium"
            type="button"
            onClick={toNext}
          >
            Dashboard
          </button>
        </div>
      </div>
    </div>
  )
}

ThankYou.propTypes = {
  domRef: PropTypes.func,
  labels: PropTypes.array,
  step: PropTypes.number,
  steps: PropTypes.array,
  toNext: PropTypes.func,
}

export default ThankYou
